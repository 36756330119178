<template>
    <div class="text_center">
        <div class="imgEle___cXgra"></div>
        <h1 class="fts">404</h1>
        <p>找不到该页面，或者您没有查看该页面的权限。</p>
        <el-button class="btn" type="primary" size="small" @click="go ">返回首页</el-button>
    </div>
</template>
<script>
    export default {
        methods: {
            go() {
                this.$router.push({
                    name: "home",
                })
            },
        }
    }
</script>
<style scoped lang="less">
    .text_center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -52%);

        .btn {
            margin-top: 10px;
        }
    }

    .imgEle___cXgra {
        height: 360px;
        width: 100%;
        max-width: 430px;
        float: right;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        background-image: url(https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg);
    }

    .fts {
        font-size: 40px;
        margin: 20px 0px;
    }

    p {
        font-size: 18px;
    }
</style>